export const Missouri: GeoJSON.Feature = {
  "type": "Feature",
  "geometry": {
    "type": "Polygon",
    "coordinates": [
      [
        [-95.765645, 40.585208],
        [-95.525392, 40.58209],
        [-94.991661, 40.575692],
        [-94.632035122502998, 40.571186037110195],
        [-94.48928, 40.570707],
        [-94.080223, 40.572899],
        [-93.548284, 40.580417],
        [-93.260612, 40.580797],
        [-92.941595, 40.587743],
        [-92.757407, 40.588908],
        [-92.096387, 40.60183],
        [-91.729115, 40.61364],
        [-91.68882, 40.583409],
        [-91.681714, 40.553035],
        [-91.625161, 40.5435],
        [-91.616948, 40.504794],
        [-91.563844, 40.460988],
        [-91.5286, 40.459002],
        [-91.524612, 40.410765],
        [-91.480251, 40.381783],
        [-91.419422, 40.378264],
        [-91.46214, 40.342414],
        [-91.506501, 40.236304],
        [-91.510322, 40.127994],
        [-91.489606, 40.057435],
        [-91.494878, 40.036453],
        [-91.420878, 39.914865],
        [-91.447844, 39.877951],
        [-91.429519, 39.837801],
        [-91.377971, 39.811273],
        [-91.361744, 39.785079],
        [-91.370009, 39.732524],
        [-91.27614, 39.665759],
        [-91.241225, 39.630067],
        [-91.185921, 39.605119],
        [-91.148275, 39.545798],
        [-91.100307, 39.538695],
        [-91.064305, 39.494643],
        [-91.059439, 39.46886],
        [-90.993789, 39.422959],
        [-90.840106, 39.340438],
        [-90.793461, 39.309498],
        [-90.726981, 39.251173],
        [-90.709146, 39.155111],
        [-90.682744, 39.088348],
        [-90.712541, 39.057064],
        [-90.678193, 38.991851],
        [-90.6614, 38.924989],
        [-90.625122, 38.888654],
        [-90.583388, 38.86903],
        [-90.54403, 38.87505],
        [-90.450792, 38.967764],
        [-90.383126, 38.955453],
        [-90.309454, 38.92412],
        [-90.250248, 38.919344],
        [-90.19761, 38.887648],
        [-90.113327, 38.849306],
        [-90.123107, 38.798048],
        [-90.166409, 38.772649],
        [-90.21201, 38.71175],
        [-90.18261, 38.66535],
        [-90.17881, 38.62915],
        [-90.191811, 38.598951],
        [-90.224212, 38.575051],
        [-90.260314, 38.528352],
        [-90.295316, 38.426753],
        [-90.349743, 38.377609],
        [-90.372519, 38.323354],
        [-90.373929, 38.281853],
        [-90.353902, 38.213855],
        [-90.322353, 38.181593],
        [-90.274928, 38.157615],
        [-90.243116, 38.112669],
        [-90.17222, 38.069636],
        [-90.130788, 38.062341],
        [-90.093774, 38.017833],
        [-90.057269, 38.014362],
        [-90.008353, 37.970179],
        [-89.942099, 37.970121],
        [-89.974221, 37.919217],
        [-89.952499, 37.883218],
        [-89.901832, 37.869822],
        [-89.844786, 37.905572],
        [-89.798041, 37.879655],
        [-89.796087, 37.859505],
        [-89.739873, 37.84693],
        [-89.66038, 37.786296],
        [-89.667993, 37.759484],
        [-89.617278, 37.74972],
        [-89.583316, 37.713261],
        [-89.512009, 37.685525],
        [-89.517718, 37.641217],
        [-89.478399, 37.598869],
        [-89.516538, 37.584504],
        [-89.516447, 37.535558],
        [-89.471201, 37.466473],
        [-89.43413, 37.426847],
        [-89.422465, 37.397132],
        [-89.43604, 37.344441],
        [-89.491194, 37.331361],
        [-89.51834, 37.285497],
        [-89.489915, 37.251315],
        [-89.460692, 37.250577],
        [-89.461862, 37.199517],
        [-89.414471, 37.12505],
        [-89.375615, 37.085936],
        [-89.378277, 37.039605],
        [-89.317168, 37.012767],
        [-89.29213, 36.992189],
        [-89.257608, 37.015496],
        [-89.301368, 37.044982],
        [-89.259936, 37.064071],
        [-89.198488, 37.011723],
        [-89.192097, 36.979995],
        [-89.132685, 36.9822],
        [-89.099594, 36.964543],
        [-89.117567, 36.887356],
        [-89.137969, 36.847349],
        [-89.173419, 36.839806],
        [-89.177417, 36.807269],
        [-89.116847, 36.775607],
        [-89.126134, 36.751735],
        [-89.166888, 36.759633],
        [-89.197808, 36.739412],
        [-89.19948, 36.716045],
        [-89.15908, 36.666352],
        [-89.197654, 36.628936],
        [-89.227319, 36.569375],
        [-89.258318, 36.564948],
        [-89.326731, 36.632186],
        [-89.375453, 36.615719],
        [-89.417275, 36.499011],
        [-89.464153, 36.457189],
        [-89.485427, 36.497491],
        [-89.465888, 36.529946],
        [-89.484836, 36.571821],
        [-89.55264, 36.577178],
        [-89.571241, 36.547343],
        [-89.539232, 36.497934],
        [-89.522674, 36.481305],
        [-89.542337, 36.420103],
        [-89.509722, 36.373626],
        [-89.531822, 36.339246],
        [-89.610689, 36.340442],
        [-89.611819, 36.309088],
        [-89.535529, 36.270541],
        [-89.534745, 36.252576],
        [-89.587326, 36.239484],
        [-89.695235, 36.252766],
        [-89.69263, 36.224959],
        [-89.607004, 36.171179],
        [-89.594, 36.12719],
        [-89.680029, 36.082494],
        [-89.687254, 36.034048],
        [-89.706932, 36.000981],
        [-89.733095, 36.000608],
        [-90.37789, 35.995683],
        [-90.337146, 36.047754],
        [-90.320662, 36.087138],
        [-90.293109, 36.114368],
        [-90.248808, 36.129835],
        [-90.220425, 36.184764],
        [-90.14224, 36.227522],
        [-90.114922, 36.265595],
        [-90.083731, 36.272332],
        [-90.06398, 36.303038],
        [-90.081961, 36.322097],
        [-90.064514, 36.382085],
        [-90.080426, 36.400763],
        [-90.135002, 36.413721],
        [-90.154409, 36.496832],
        [-90.711226, 36.498318],
        [-91.404915, 36.49712],
        [-91.53687, 36.499156],
        [-91.985802, 36.498431],
        [-92.350277, 36.497787],
        [-92.772340678574693, 36.497772035561795],
        [-93.125969, 36.497851],
        [-93.728022, 36.499037],
        [-94.110673, 36.498587],
        [-94.617919, 36.499414],
        [-94.617964, 36.998905],
        [-94.616789, 37.52151],
        [-94.617721, 37.77297],
        [-94.614612, 37.944362],
        [-94.612658, 38.217649],
        [-94.611858, 38.620485],
        [-94.607625, 38.82756],
        [-94.60829, 39.117944],
        [-94.588413, 39.149869],
        [-94.639779, 39.153707],
        [-94.687236, 39.183503],
        [-94.736537, 39.169203],
        [-94.787343, 39.207666],
        [-94.823791, 39.209874],
        [-94.825663, 39.241729],
        [-94.84632, 39.268481],
        [-94.900049, 39.300192],
        [-94.910641, 39.348335],
        [-94.879088, 39.375703],
        [-94.92311, 39.384492],
        [-94.990172, 39.446192],
        [-95.033408, 39.460876],
        [-95.049845, 39.494415],
        [-95.102888, 39.533347],
        [-95.107454, 39.573843],
        [-95.056897, 39.580567],
        [-95.055152, 39.621657],
        [-95.027644, 39.665454],
        [-94.968981, 39.692954],
        [-94.955286, 39.745689],
        [-94.902612, 39.724202],
        [-94.875643, 39.730494],
        [-94.860743, 39.763094],
        [-94.916789, 39.760938],
        [-94.925605, 39.789754],
        [-94.876344, 39.806894],
        [-94.886933, 39.833098],
        [-94.942567, 39.856602],
        [-94.927897, 39.886112],
        [-94.959276, 39.901671],
        [-95.018743, 39.897372],
        [-95.037767, 39.865542],
        [-95.081534, 39.861718],
        [-95.140601, 39.881688],
        [-95.143802, 39.901918],
        [-95.205733, 39.908275],
        [-95.201277, 39.934194],
        [-95.241383, 39.944949],
        [-95.302507, 39.984357],
        [-95.30829, 39.999998],
        [-95.363983, 40.031498],
        [-95.413588, 40.038424],
        [-95.42164, 40.058952],
        [-95.398667, 40.126419],
        [-95.476301, 40.181988],
        [-95.477501, 40.24272],
        [-95.556275, 40.270761],
        [-95.553292, 40.291158],
        [-95.610439, 40.31397],
        [-95.661463, 40.415947],
        [-95.65819, 40.44188],
        [-95.693133, 40.469396],
        [-95.69721, 40.528477],
        [-95.75711, 40.52599],
        [-95.765645, 40.585208]
      ]
    ]
  },
  "properties": {}
};
