
export const counties = [
  { name: 'Adair', value: 'Adair' },
  { name: 'Andrew', value: 'Andrew' },
  { name: 'Atchison', value: 'Atchison' },
  { name: 'Audrain', value: 'Audrain' },
  { name: 'Barry', value: 'Barry' },
  { name: 'Barton', value: 'Barton' },
  { name: 'Bates', value: 'Bates' },
  { name: 'Benton', value: 'Benton' },
  { name: 'Bollinger', value: 'Bollinger' },
  { name: 'Boone', value: 'Boone' },
  { name: 'Buchanan', value: 'Buchanan' },
  { name: 'Butler', value: 'Butler' },
  { name: 'Caldwell', value: 'Caldwell' },
  { name: 'Callaway', value: 'Callaway' },
  { name: 'Camden', value: 'Camden' },
  { name: 'Cape Girardeau', value: 'Cape Girardeau' },
  { name: 'Carroll', value: 'Carroll' },
  { name: 'Carter', value: 'Carter' },
  { name: 'Cass', value: 'Cass' },
  { name: 'Cedar', value: 'Cedar' },
  { name: 'Chariton', value: 'Chariton' },
  { name: 'Christian', value: 'Christian' },
  { name: 'Clark', value: 'Clark' },
  { name: 'Clay', value: 'Clay' },
  { name: 'Clinton', value: 'Clinton' },
  { name: 'Cole', value: 'Cole' },
  { name: 'Cooper', value: 'Cooper' },
  { name: 'Crawford', value: 'Crawford' },
  { name: 'Dade', value: 'Dade' },
  { name: 'Dallas', value: 'Dallas' },
  { name: 'Daviess', value: 'Daviess' },
  { name: 'DeKalb', value: 'DeKalb' },
  { name: 'Dent', value: 'Dent' },
  { name: 'Douglas', value: 'Douglas' },
  { name: 'Dunklin', value: 'Dunklin' },
  { name: 'Franklin', value: 'Franklin' },
  { name: 'Gasconade', value: 'Gasconade' },
  { name: 'Gentry', value: 'Gentry' },
  { name: 'Greene', value: 'Greene' },
  { name: 'Grundy', value: 'Grundy' },
  { name: 'Harrison', value: 'Harrison' },
  { name: 'Henry', value: 'Henry' },
  { name: 'Hickory', value: 'Hickory' },
  { name: 'Holt', value: 'Holt' },
  { name: 'Howard', value: 'Howard' },
  { name: 'Howell', value: 'Howell' },
  { name: 'Iron', value: 'Iron' },
  { name: 'Jackson', value: 'Jackson' },
  { name: 'Jasper', value: 'Jasper' },
  { name: 'Jefferson', value: 'Jefferson' },
  { name: 'Johnson', value: 'Johnson' },
  { name: 'Knox', value: 'Knox' },
  { name: 'Laclede', value: 'Laclede' },
  { name: 'Lafayette', value: 'Lafayette' },
  { name: 'Lawrence', value: 'Lawrence' },
  { name: 'Lewis', value: 'Lewis' },
  { name: 'Lincoln', value: 'Lincoln' },
  { name: 'Linn', value: 'Linn' },
  { name: 'Livingston', value: 'Livingston' },
  { name: 'Macon', value: 'Macon' },
  { name: 'Madison', value: 'Madison' },
  { name: 'Maries', value: 'Maries' },
  { name: 'Marion', value: 'Marion' },
  { name: 'McDonald', value: 'McDonald' },
  { name: 'Mercer', value: 'Mercer' },
  { name: 'Miller', value: 'Miller' },
  { name: 'Mississippi', value: 'Mississippi' },
  { name: 'Moniteau', value: 'Moniteau' },
  { name: 'Monroe', value: 'Monroe' },
  { name: 'Montgomery', value: 'Montgomery' },
  { name: 'Morgan', value: 'Morgan' },
  { name: 'New Madrid', value: 'New Madrid' },
  { name: 'Newton', value: 'Newton' },
  { name: 'Nodaway', value: 'Nodaway' },
  { name: 'Oregon', value: 'Oregon' },
  { name: 'Osage', value: 'Osage' },
  { name: 'Ozark', value: 'Ozark' },
  { name: 'Pemiscot', value: 'Pemiscot' },
  { name: 'Perry', value: 'Perry' },
  { name: 'Pettis', value: 'Pettis' },
  { name: 'Phelps', value: 'Phelps' },
  { name: 'Pike', value: 'Pike' },
  { name: 'Platte', value: 'Platte' },
  { name: 'Polk', value: 'Polk' },
  { name: 'Pulaski', value: 'Pulaski' },
  { name: 'Putnam', value: 'Putnam' },
  { name: 'Ralls', value: 'Ralls' },
  { name: 'Randolph', value: 'Randolph' },
  { name: 'Ray', value: 'Ray' },
  { name: 'Reynolds', value: 'Reynolds' },
  { name: 'Ripley', value: 'Ripley' },
  { name: 'Saint Charles', value: 'St. Charles' },
  { name: 'Saint Clair', value: 'St. Clair' },
  { name: 'Saint Francois', value: 'St. Francois' },
  { name: 'Saint Louis', value: 'St. Louis' },
  { name: 'Saint Louis City', value: 'St. Louis City' },
  { name: 'Sainte Genevieve', value: 'Ste. Genevieve' },
  { name: 'Saline', value: 'Saline' },
  { name: 'Schuyler', value: 'Schuyler' },
  { name: 'Scotland', value: 'Scotland' },
  { name: 'Scott', value: 'Scott' },
  { name: 'Shannon', value: 'Shannon' },
  { name: 'Shelby', value: 'Shelby' },
  { name: 'Stoddard', value: 'Stoddard' },
  { name: 'Stone', value: 'Stone' },
  { name: 'Sullivan', value: 'Sullivan' },
  { name: 'Taney', value: 'Taney' },
  { name: 'Texas', value: 'Texas' },
  { name: 'Vernon', value: 'Vernon' },
  { name: 'Warren', value: 'Warren' },
  { name: 'Washington', value: 'Washington' },
  { name: 'Wayne', value: 'Wayne' },
  { name: 'Webster', value: 'Webster' },
  { name: 'Worth', value: 'Worth' },
  { name: 'Wright', value: 'Wright' }
];

export const probeCounties = [
  { name: 'ST CLAIR', value: 'ST CLAIR' },
  { name: 'CAPE GIRARDEAU', value: 'CAPE GIRARDEAU' },
  { name: 'SALINE', value: 'SALINE' },
  { name: 'CAMDEN', value: 'CAMDEN' },
  { name: 'KNOX', value: 'KNOX' },
  { name: 'MARION', value: 'MARION' },
  { name: 'CLARK', value: 'CLARK' },
  { name: 'JASPER', value: 'JASPER' },
  { name: 'OREGON', value: 'OREGON' },
  { name: 'HOLT', value: 'HOLT' },
  { name: 'STONE', value: 'STONE' },
  { name: 'SHELBY', value: 'SHELBY' },
  { name: 'ST LOUIS CITY', value: 'ST LOUIS CITY' },
  { name: 'HOWARD', value: 'HOWARD' },
  { name: 'ST CHARLES', value: 'ST CHARLES' },
  { name: 'DALLAS', value: 'DALLAS' },
  { name: 'CRAWFORD', value: 'CRAWFORD' },
  { name: 'ANDREW', value: 'ANDREW' },
  { name: 'BENTON', value: 'BENTON' },
  { name: 'ADAIR', value: 'ADAIR' },
  { name: 'PIKE', value: 'PIKE' },
  { name: 'VERNON', value: 'VERNON' },
  { name: 'LINN', value: 'LINN' },
  { name: 'PHELPS', value: 'PHELPS' },
  { name: 'ST. FRANCOIS', value: 'ST. FRANCOIS' },
  { name: 'STODDARD', value: 'STODDARD' },
  { name: 'GENTRY', value: 'GENTRY' },
  { name: 'ST. CLAIR', value: 'ST. CLAIR' },
  { name: 'CALLAWAY', value: 'CALLAWAY' },
  { name: 'WAYNE', value: 'WAYNE' },
  { name: 'RIPLEY', value: 'RIPLEY' },
  { name: 'CASS', value: 'CASS' },
  { name: 'COOPER', value: 'COOPER' },
  { name: 'MACON', value: 'MACON' },
  { name: 'DUNKLIN', value: 'DUNKLIN' },
  { name: 'ST. CHARLES', value: 'ST. CHARLES' },
  { name: 'DENT', value: 'DENT' },
  { name: 'STE GENEVIEVE', value: 'STE GENEVIEVE' },
  { name: 'SHANNON', value: 'SHANNON' },
  { name: 'ST FRANCOIS', value: 'ST FRANCOIS' },
  { name: 'LACLEDE', value: 'LACLEDE' },
  { name: 'MISSISSIPPI', value: 'MISSISSIPPI' },
  { name: 'SCHUYLER', value: 'SCHUYLER' },
  { name: 'STE. GENEVIEVE', value: 'STE. GENEVIEVE' },
  { name: 'PERRY', value: 'PERRY' },
  { name: 'CARTER', value: 'CARTER' },
  { name: 'GASCONADE', value: 'GASCONADE' },
  { name: 'CHRISTIAN', value: 'CHRISTIAN' },
  { name: 'TEXAS', value: 'TEXAS' },
  { name: 'HARRISON', value: 'HARRISON' },
  { name: 'CLAY', value: 'CLAY' },
  { name: 'MONROE', value: 'MONROE' },
  { name: 'JACKSON', value: 'JACKSON' },
  { name: 'BOONE', value: 'BOONE' },
  { name: 'HENRY', value: 'HENRY' },
  { name: 'SCOTLAND', value: 'SCOTLAND' },
  { name: 'COLE', value: 'COLE' },
  { name: 'DAVIESS', value: 'DAVIESS' },
  { name: 'MONITEAU', value: 'MONITEAU' },
  { name: 'CEDAR', value: 'CEDAR' },
  { name: 'LIVINGSTON', value: 'LIVINGSTON' },
  { name: 'MORGAN', value: 'MORGAN' },
  { name: 'DADE', value: 'DADE' },
  { name: 'LAFAYETTE', value: 'LAFAYETTE' },
  { name: 'JOHNSON', value: 'JOHNSON' },
  { name: 'LEWIS', value: 'LEWIS' },
  { name: 'BARTON', value: 'BARTON' },
  { name: 'ATCHISON', value: 'ATCHISON' },
  { name: 'FRANKLIN', value: 'FRANKLIN' },
  { name: 'BUTLER', value: 'BUTLER' },
  { name: 'IRON', value: 'IRON' },
  { name: 'BARRY', value: 'BARRY' },
  { name: 'OZARK', value: 'OZARK' },
  { name: 'ST. LOUIS CITY', value: 'ST. LOUIS CITY' },
  { name: 'WORTH', value: 'WORTH' },
  { name: 'BATES', value: 'BATES' },
  { name: 'SCOTT', value: 'SCOTT' },
  { name: 'HICKORY', value: 'HICKORY' },
  { name: 'LINCOLN', value: 'LINCOLN' },
  { name: 'MERCER', value: 'MERCER' },
  { name: 'RALLS', value: 'RALLS' },
  { name: 'WRIGHT', value: 'WRIGHT' },
  { name: 'HOWELL', value: 'HOWELL' },
  { name: 'BOLLINGER', value: 'BOLLINGER' },
  { name: 'MONTGOMERY', value: 'MONTGOMERY' },
  { name: 'MARIES', value: 'MARIES' },
  { name: 'CHARITON', value: 'CHARITON' },
  { name: 'REYNOLDS', value: 'REYNOLDS' },
  { name: 'GRUNDY', value: 'GRUNDY' },
  { name: 'ST LOUIS', value: 'ST LOUIS' },
  { name: 'TANEY', value: 'TANEY' },
  { name: 'RANDOLPH', value: 'RANDOLPH' },
  { name: 'PULASKI', value: 'PULASKI' },
  { name: 'WEBSTER', value: 'WEBSTER' },
  { name: 'LAWRENCE', value: 'LAWRENCE' },
  { name: 'POLK', value: 'POLK' },
  { name: 'NEW MADRID', value: 'NEW MADRID' },
  { name: 'PETTIS', value: 'PETTIS' },
  { name: 'RAY', value: 'RAY' },
  { name: 'MCDONALD', value: 'MCDONALD' },
  { name: 'ST. LOUIS', value: 'ST. LOUIS' },
  { name: 'PEMISCOT', value: 'PEMISCOT' },
  { name: 'MILLER', value: 'MILLER' },
  { name: 'GREENE', value: 'GREENE' },
  { name: 'WASHINGTON', value: 'WASHINGTON' },
  { name: 'CARROLL', value: 'CARROLL' },
  { name: 'PLATTE', value: 'PLATTE' },
  { name: 'WARREN', value: 'WARREN' },
  { name: 'PUTNAM', value: 'PUTNAM' },
  { name: 'BUCHANAN', value: 'BUCHANAN' },
  { name: 'CALDWELL', value: 'CALDWELL' },
  { name: 'NEWTON', value: 'NEWTON' },
  { name: 'OSAGE', value: 'OSAGE' },
  { name: 'MADISON', value: 'MADISON' },
  { name: 'AUDRAIN', value: 'AUDRAIN' },
  { name: 'CLINTON', value: 'CLINTON' },
  { name: 'DEKALB', value: 'DEKALB' },
  { name: 'JEFFERSON', value: 'JEFFERSON' },
  { name: 'NODAWAY', value: 'NODAWAY' },
  { name: 'SULLIVAN', value: 'SULLIVAN' },
  { name: 'DOUGLAS', value: 'DOUGLAS' },
];

export const countiesWithAlias=[
  { name: 'Adair', value: ['Adair']},
  { name: 'Andrew', value: ['Andrew' ]},
  { name: 'Atchison', value: ['Atchison' ]},
  { name: 'Audrain', value: ['Audrain' ]},
  { name: 'Barry', value: ['Barry' ]},
  { name: 'Barton', value: ['Barton' ]},
  { name: 'Bates', value: ['Bates' ]},
  { name: 'Benton', value: ['Benton' ]},
  { name: 'Bollinger', value: ['Bollinger' ]},
  { name: 'Boone', value: ['Boone' ]},
  { name: 'Buchanan', value: ['Buchanan' ]},
  { name: 'Butler', value: ['Butler' ]},
  { name: 'Caldwell', value: ['Caldwell' ]},
  { name: 'Callaway', value: ['Callaway' ]},
  { name: 'Camden', value: ['Camden' ]},
  { name: 'Cape Girardeau', value: ['Cape Girardeau' ]},
  { name: 'Carroll', value: ['Carroll' ]},
  { name: 'Carter', value: ['Carter' ]},
  { name: 'Cass', value: ['Cass' ]},
  { name: 'Cedar', value: ['Cedar' ]},
  { name: 'Chariton', value: ['Chariton' ]},
  { name: 'Christian', value: ['Christian' ]},
  { name: 'Clark', value: ['Clark' ]},
  { name: 'Clay', value: ['Clay' ]},
  { name: 'Clinton', value: ['Clinton' ]},
  { name: 'Cole', value: ['Cole' ]},
  { name: 'Cooper', value: ['Cooper' ]},
  { name: 'Crawford', value: ['Crawford' ]},
  { name: 'Dade', value: ['Dade' ]},
  { name: 'Dallas', value: ['Dallas' ]},
  { name: 'Daviess', value: ['Daviess' ]},
  { name: 'DeKalb', value: ['DeKalb' ]},
  { name: 'Dent', value: ['Dent' ]},
  { name: 'Douglas', value: ['Douglas' ]},
  { name: 'Dunklin', value: ['Dunklin' ]},
  { name: 'Franklin', value: ['Franklin' ]},
  { name: 'Gasconade', value: ['Gasconade' ]},
  { name: 'Gentry', value: ['Gentry' ]},
  { name: 'Greene', value: ['Greene' ]},
  { name: 'Grundy', value: ['Grundy' ]},
  { name: 'Harrison', value: ['Harrison' ]},
  { name: 'Henry', value: ['Henry' ]},
  { name: 'Hickory', value: ['Hickory' ]},
  { name: 'Holt', value: ['Holt' ]},
  { name: 'Howard', value: ['Howard' ]},
  { name: 'Howell', value: ['Howell' ]},
  { name: 'Iron', value: ['Iron' ]},
  { name: 'Jackson', value: ['Jackson' ]},
  { name: 'Jasper', value: ['Jasper' ]},
  { name: 'Jefferson', value: ['Jefferson' ]},
  { name: 'Johnson', value: ['Johnson' ]},
  { name: 'Knox', value: ['Knox' ]},
  { name: 'Laclede', value: ['Laclede' ]},
  { name: 'Lafayette', value: ['Lafayette' ]},
  { name: 'Lawrence', value: ['Lawrence' ]},
  { name: 'Lewis', value: ['Lewis' ]},
  { name: 'Lincoln', value: ['Lincoln' ]},
  { name: 'Linn', value: ['Linn' ]},
  { name: 'Livingston', value: ['Livingston' ]},
  { name: 'Macon', value: ['Macon' ]},
  { name: 'Madison', value: ['Madison' ]},
  { name: 'Maries', value: ['Maries' ]},
  { name: 'Marion', value: ['Marion' ]},
  { name: 'McDonald', value: ['McDonald' ]},
  { name: 'Mercer', value: ['Mercer' ]},
  { name: 'Miller', value: ['Miller' ]},
  { name: 'Mississippi', value: ['Mississippi' ]},
  { name: 'Moniteau', value: ['Moniteau' ]},
  { name: 'Monroe', value: ['Monroe' ]},
  { name: 'Montgomery', value: ['Montgomery' ]},
  { name: 'Morgan', value: ['Morgan' ]},
  { name: 'New Madrid', value: ['New Madrid' ]},
  { name: 'Newton', value: ['Newton' ]},
  { name: 'Nodaway', value: ['Nodaway' ]},
  { name: 'Oregon', value: ['Oregon' ]},
  { name: 'Osage', value: ['Osage' ]},
  { name: 'Ozark', value: ['Ozark' ]},
  { name: 'Pemiscot', value: ['Pemiscot' ]},
  { name: 'Perry', value: ['Perry' ]},
  { name: 'Pettis', value: ['Pettis' ]},
  { name: 'Phelps', value: ['Phelps' ]},
  { name: 'Pike', value: ['Pike' ]},
  { name: 'Platte', value: ['Platte' ]},
  { name: 'Polk', value: ['Polk' ]},
  { name: 'Pulaski', value: ['Pulaski' ]},
  { name: 'Putnam', value: ['Putnam' ]},
  { name: 'Ralls', value: ['Ralls' ]},
  { name: 'Randolph', value: ['Randolph' ]},
  { name: 'Ray', value: ['Ray' ]},
  { name: 'Reynolds', value: ['Reynolds' ]},
  { name: 'Ripley', value: ['Ripley' ]},
  { name: 'Saint Charles', value: ['St. Charles','ST CHARLES','Saint Charles' ]},
  { name: 'Saint Clair', value: ['St. Clair','ST CLAIR','Saint Clair' ]},
  { name: 'Saint Francois', value: ['St. Francois','ST FRANCOIS','Saint Francois' ]},
  { name: 'Saint Louis', value: ['ST. LOUIS','Saint Louis','ST LOUIS' ]},
  { name: 'Saint Louis City', value: ['St. Louis City','ST LOUIS CITY','Saint Louis City' ]},
  { name: 'Sainte Genevieve', value: ['Ste. Genevieve','STE GENEVIEVE','Sainte Genevieve']},
  { name: 'Saline', value: ['Saline' ]},
  { name: 'Schuyler', value: ['Schuyler' ]},
  { name: 'Scotland', value: ['Scotland' ]},
  { name: 'Scott', value: ['Scott' ]},
  { name: 'Shannon', value: ['Shannon' ]},
  { name: 'Shelby', value: ['Shelby' ]},
  { name: 'Stoddard', value: ['Stoddard' ]},
  { name: 'Stone', value: ['Stone' ]},
  { name: 'Sullivan', value: ['Sullivan' ]},
  { name: 'Taney', value: ['Taney' ]},
  { name: 'Texas', value: ['Texas' ]},
  { name: 'Vernon', value: ['Vernon' ]},
  { name: 'Warren', value: ['Warren' ]},
  { name: 'Washington', value: ['Washington' ]},
  { name: 'Wayne', value: ['Wayne' ]},
  { name: 'Webster', value: ['Webster' ]},
  { name: 'Worth', value: ['Worth' ]},
  { name: 'Wright', value: ['Wright' ]},
  { name: 'All', value: [
    "Adair",
    "Andrew",
    "Atchison",
    "Audrain",
    "Barry",
    "Barton",
    "Bates",
    "Benton",
    "Bollinger",
    "Boone",
    "Buchanan",
    "Butler",
    "Caldwell",
    "Callaway",
    "Camden",
    "Cape Girardeau",
    "Carroll",
    "Carter",
    "Cass",
    "Cedar",
    "Chariton",
    "Christian",
    "Clark",
    "Clay",
    "Clinton",
    "Cole",
    "Cooper",
    "Crawford",
    "Dade",
    "Dallas",
    "Daviess",
    "DeKalb",
    "Dent",
    "Douglas",
    "Dunklin",
    "Franklin",
    "Gasconade",
    "Gentry",
    "Greene",
    "Grundy",
    "Harrison",
    "Henry",
    "Hickory",
    "Holt",
    "Howard",
    "Howell",
    "Iron",
    "Jackson",
    "Jasper",
    "Jefferson",
    "Johnson",
    "Knox",
    "Laclede",
    "Lafayette",
    "Lawrence",
    "Lewis",
    "Lincoln",
    "Linn",
    "Livingston",
    "Macon",
    "Madison",
    "Maries",
    "Marion",
    "McDonald",
    "Mercer",
    "Miller",
    "Mississippi",
    "Moniteau",
    "Monroe",
    "Montgomery",
    "Morgan",
    "New Madrid",
    "Newton",
    "Nodaway",
    "Oregon",
    "Osage",
    "Ozark",
    "Pemiscot",
    "Perry",
    "Pettis",
    "Phelps",
    "Pike",
    "Platte",
    "Polk",
    "Pulaski",
    "Putnam",
    "Ralls",
    "Randolph",
    "Ray",
    "Reynolds",
    "Ripley",
    "St. Charles",
    "ST CHARLES",
    "Saint Charles",
    "St. Clair",
    "ST CLAIR",
    "Saint Clair",
    "St. Francois",
    "ST FRANCOIS",
    "Saint Francois",
    "ST. LOUIS",
    "Saint Louis",
    "ST LOUIS",
    "St. Louis City",
    "ST LOUIS CITY",
    "Saint Louis City",
    "Ste. Genevieve",
    "STE GENEVIEVE",
    "Sainte Genevieve",
    "Saline",
    "Schuyler",
    "Scotland",
    "Scott",
    "Shannon",
    "Shelby",
    "Stoddard",
    "Stone",
    "Sullivan",
    "Taney",
    "Texas",
    "Vernon",
    "Warren",
    "Washington",
    "Wayne",
    "Webster",
    "Worth",
    "Wright"
  ]}
];

export const wazeIncidentsCounties = [
  { name: 'POLK', value: 'POLK' },
  { name: 'CEDAR', value: 'CEDAR' },
  { name: 'OSAGE', value: 'OSAGE' },
  { name: 'JASPER', value: 'JASPER' },
  { name: 'HENRY', value: 'HENRY' },
  { name: 'HOWARD', value: 'HOWARD' },
  { name: 'OREGON', value: 'OREGON' },
  { name: 'DENT', value: 'DENT' },
  { name: 'PIKE', value: 'PIKE' },
  { name: 'PETTIS', value: 'PETTIS' },
  { name: 'CARROLL', value: 'CARROLL' },
  { name: 'JOHNSON', value: 'JOHNSON' },
  { name: 'WAYNE', value: 'WAYNE' },
  { name: 'OZARK', value: 'OZARK' },
  { name: 'KNOX', value: 'KNOX' },
  { name: 'ST LOUIS CITY', value: 'ST LOUIS CITY' },
  { name: 'IRON', value: 'IRON' },
  { name: 'JACKSON', value: 'JACKSON' },
  { name: 'CRAWFORD', value: 'CRAWFORD' },
  { name: 'HICKORY', value: 'HICKORY' },
  { name: 'PULASKI', value: 'PULASKI' },
  { name: 'CLARK', value: 'CLARK' },
  { name: 'PHELPS', value: 'PHELPS' },
  { name: 'LEWIS', value: 'LEWIS' },
  { name: 'LACLEDE', value: 'LACLEDE' },
  { name: 'DAVIESS', value: 'DAVIESS' },
  { name: 'COOPER', value: 'COOPER' },
  { name: 'MARION', value: 'MARION' },
  { name: 'DEKALB', value: 'DEKALB' },
  { name: 'BARRY', value: 'BARRY' },
  { name: 'HARRISON', value: 'HARRISON' },
  { name: 'CARTER', value: 'CARTER' },
  { name: 'BENTON', value: 'BENTON' },
  { name: 'MONITEAU', value: 'MONITEAU' },
  { name: 'ST. CLAIR', value: 'ST. CLAIR' },
  { name: 'STODDARD', value: 'STODDARD' },
  { name: 'CLINTON', value: 'CLINTON' },
  { name: 'GRUNDY', value: 'GRUNDY' },
  { name: 'LINCOLN', value: 'LINCOLN' },
  { name: 'ANDREW', value: 'ANDREW' },
  { name: 'FRANKLIN', value: 'FRANKLIN' },
  { name: 'CAPE GIRARDEAU', value: 'CAPE GIRARDEAU' },
  { name: 'COLE', value: 'COLE' },
  { name: 'WARREN', value: 'WARREN' },
  { name: 'CAMDEN', value: 'CAMDEN' },
  { name: 'MADISON', value: 'MADISON' },
  { name: 'BOLLINGER', value: 'BOLLINGER' },
  { name: 'RALLS', value: 'RALLS' },
  { name: 'BATES', value: 'BATES' },
  { name: 'GENTRY', value: 'GENTRY' },
  { name: 'MCDONALD', value: 'MCDONALD' },
  { name: 'MONROE', value: 'MONROE' },
  { name: 'CASS', value: 'CASS' },
  { name: 'ATCHISON', value: 'ATCHISON' },
  { name: 'MILLER', value: 'MILLER' },
  { name: 'RIPLEY', value: 'RIPLEY' },
  { name: 'PUTNAM', value: 'PUTNAM' },
  { name: 'ST. LOUIS', value: 'ST. LOUIS' },
  { name: 'ST. LOUIS CITY', value: 'ST. LOUIS CITY' },
  { name: 'ST LOUIS', value: 'ST LOUIS' },
  { name: 'JEFFERSON', value: 'JEFFERSON' },
  { name: 'PLATTE', value: 'PLATTE' },
  { name: 'SULLIVAN', value: 'SULLIVAN' },
  { name: 'CHARITON', value: 'CHARITON' },
  { name: 'NODAWAY', value: 'NODAWAY' },
  { name: 'PERRY', value: 'PERRY' },
  { name: 'SCOTT', value: 'SCOTT' },
  { name: 'RAY', value: 'RAY' },
  { name: 'RANDOLPH', value: 'RANDOLPH' },
  { name: 'BUTLER', value: 'BUTLER' },
  { name: 'CALLAWAY', value: 'CALLAWAY' },
  { name: 'HOLT', value: 'HOLT' },
  { name: 'MACON', value: 'MACON' },
  { name: 'REYNOLDS', value: 'REYNOLDS' },
  { name: 'DUNKLIN', value: 'DUNKLIN' },
  { name: 'BARTON', value: 'BARTON' },
  { name: 'TANEY', value: 'TANEY' },
  { name: 'MERCER', value: 'MERCER' },
  { name: 'ST. FRANCOIS', value: 'ST. FRANCOIS' },
  { name: 'STE. GENEVIEVE', value: 'STE. GENEVIEVE' },
  { name: 'ST CHARLES', value: 'ST CHARLES' },
  { name: 'LIVINGSTON', value: 'LIVINGSTON' },
  { name: 'CLAY', value: 'CLAY' },
  { name: 'DOUGLAS', value: 'DOUGLAS' },
  { name: 'HOWELL', value: 'HOWELL' },
  { name: 'ST FRANCOIS', value: 'ST FRANCOIS' },
  { name: 'LINN', value: 'LINN' },
  { name: 'ADAIR', value: 'ADAIR' },
  { name: 'AUDRAIN', value: 'AUDRAIN' },
  { name: 'DALLAS', value: 'DALLAS' },
  { name: 'LAWRENCE', value: 'LAWRENCE' },
  { name: 'STE GENEVIEVE', value: 'STE GENEVIEVE' },
  { name: 'SHANNON', value: 'SHANNON' },
  { name: 'CHRISTIAN', value: 'CHRISTIAN' },
  { name: 'WRIGHT', value: 'WRIGHT' },
  { name: 'MISSISSIPPI', value: 'MISSISSIPPI' },
  { name: 'GASCONADE', value: 'GASCONADE' },
  { name: 'STONE', value: 'STONE' },
  { name: 'WORTH', value: 'WORTH' },
  { name: 'ST. CHARLES', value: 'ST. CHARLES' },
  { name: 'PEMISCOT', value: 'PEMISCOT' },
  { name: 'NEWTON', value: 'NEWTON' },
  { name: 'MORGAN', value: 'MORGAN' },
  { name: 'ST CLAIR', value: 'ST CLAIR' },
  { name: 'GREENE', value: 'GREENE' },
  { name: 'SHELBY', value: 'SHELBY' },
  { name: 'BOONE', value: 'BOONE' },
  { name: 'NEW MADRID', value: 'NEW MADRID' },
  { name: 'BUCHANAN', value: 'BUCHANAN' },
  { name: 'LAFAYETTE', value: 'LAFAYETTE' },
  { name: 'WASHINGTON', value: 'WASHINGTON' },
  { name: 'WEBSTER', value: 'WEBSTER' },
  { name: 'DADE', value: 'DADE' },
  { name: 'VERNON', value: 'VERNON' },
  { name: 'SALINE', value: 'SALINE' },
  { name: 'MONTGOMERY', value: 'MONTGOMERY' },
  { name: 'CALDWELL', value: 'CALDWELL' },
  { name: 'TEXAS', value: 'TEXAS' },
  { name: 'SCHUYLER', value: 'SCHUYLER' },
  { name: 'SCOTLAND', value: 'SCOTLAND' },
  { name: 'MARIES', value: 'MARIES' },];

export const wazeJamCounties = [
  {name: 'HICKORY', value: 'HICKORY'},
{name: 'BARRY', value: 'BARRY'},
{name: 'CARTER', value: 'CARTER'},
{name: 'MARIES', value: 'MARIES'},
{name: 'HOWELL', value: 'HOWELL'},
{name: 'RALLS', value: 'RALLS'},
{name: 'JASPER', value: 'JASPER'},
{name: 'RANDOLPH', value: 'RANDOLPH'},
{name: 'DAVIESS', value: 'DAVIESS'},
{name: 'ST CHARLES', value: 'ST CHARLES'},
{name: 'HENRY', value: 'HENRY'},
{name: 'COLE', value: 'COLE'},
{name: 'SHELBY', value: 'SHELBY'},
{name: 'JOHNSON', value: 'JOHNSON'},
{name: 'REYNOLDS', value: 'REYNOLDS'},
{name: 'CHARITON', value: 'CHARITON'},
{name: 'CALLAWAY', value: 'CALLAWAY'},
{name: 'NEW MADRID', value: 'NEW MADRID'},
{name: 'MONITEAU', value: 'MONITEAU'},
{name: 'TANEY', value: 'TANEY'},
{name: 'BOONE', value: 'BOONE'},
{name: 'PHELPS', value: 'PHELPS'},
{name: 'BARTON', value: 'BARTON'},
{name: 'LAFAYETTE', value: 'LAFAYETTE'},
{name: 'STONE', value: 'STONE'},
{name: 'BOLLINGER', value: 'BOLLINGER'},
{name: 'MILLER', value: 'MILLER'},
{name: 'PERRY', value: 'PERRY'},
{name: 'CEDAR', value: 'CEDAR'},
{name: 'OREGON', value: 'OREGON'},
{name: 'HARRISON', value: 'HARRISON'},
{name: 'LAWRENCE', value: 'LAWRENCE'},
{name: 'COOPER', value: 'COOPER'},
{name: 'STODDARD', value: 'STODDARD'},
{name: 'ST. CHARLES', value: 'ST. CHARLES'},
{name: 'BUCHANAN', value: 'BUCHANAN'},
{name: 'PLATTE', value: 'PLATTE'},
{name: 'POLK', value: 'POLK'},
{name: 'BENTON', value: 'BENTON'},
{name: 'PIKE', value: 'PIKE'},
{name: 'OZARK', value: 'OZARK'},
{name: 'GRUNDY', value: 'GRUNDY'},
{name: 'IRON', value: 'IRON'},
{name: 'CLAY', value: 'CLAY'},
{name: 'OSAGE', value: 'OSAGE'},
{name: 'ATCHISON', value: 'ATCHISON'},
{name: 'WAYNE', value: 'WAYNE'},
{name: 'DUNKLIN', value: 'DUNKLIN'},
{name: 'NEWTON', value: 'NEWTON'},
{name: 'SULLIVAN', value: 'SULLIVAN'},
{name: 'PULASKI', value: 'PULASKI'},
{name: 'FRANKLIN', value: 'FRANKLIN'},
{name: 'DENT', value: 'DENT'},
{name: 'SALINE', value: 'SALINE'},
{name: 'LINN', value: 'LINN'},
{name: 'RIPLEY', value: 'RIPLEY'},
{name: 'PETTIS', value: 'PETTIS'},
{name: 'SCOTLAND', value: 'SCOTLAND'},
{name: 'ST. LOUIS CITY', value: 'ST. LOUIS CITY'},
{name: 'ST LOUIS CITY', value: 'ST LOUIS CITY'},
{name: 'JACKSON', value: 'JACKSON'},
{name: 'SCOTT', value: 'SCOTT'},
{name: 'DOUGLAS', value: 'DOUGLAS'},
{name: 'CRAWFORD', value: 'CRAWFORD'},
{name: 'NODAWAY', value: 'NODAWAY'},
{name: 'MONROE', value: 'MONROE'},
{name: 'CLINTON', value: 'CLINTON'},
{name: 'STE GENEVIEVE', value: 'STE GENEVIEVE'},
{name: 'RAY', value: 'RAY'},
{name: 'ADAIR', value: 'ADAIR'},
{name: 'ST LOUIS', value: 'ST LOUIS'},
{name: 'LINCOLN', value: 'LINCOLN'},
{name: 'DALLAS', value: 'DALLAS'},
{name: 'WEBSTER', value: 'WEBSTER'},
{name: 'SCHUYLER', value: 'SCHUYLER'},
{name: 'CAMDEN', value: 'CAMDEN'},
{name: 'ST. LOUIS', value: 'ST. LOUIS'},
{name: 'ST. FRANCOIS', value: 'ST. FRANCOIS'},
{name: 'JEFFERSON', value: 'JEFFERSON'},
{name: 'MONTGOMERY', value: 'MONTGOMERY'},
{name: 'LACLEDE', value: 'LACLEDE'},
{name: 'WRIGHT', value: 'WRIGHT'},
{name: 'TEXAS', value: 'TEXAS'},
{name: 'GREENE', value: 'GREENE'},
{name: 'VERNON', value: 'VERNON'},
{name: 'WASHINGTON', value: 'WASHINGTON'},
{name: 'MACON', value: 'MACON'},
{name: 'WARREN', value: 'WARREN'},
{name: 'BUTLER', value: 'BUTLER'},
{name: 'MARION', value: 'MARION'},
{name: 'AUDRAIN', value: 'AUDRAIN'},
{name: 'ST FRANCOIS', value: 'ST FRANCOIS'},
{name: 'ANDREW', value: 'ANDREW'},
{name: 'GENTRY', value: 'GENTRY'},
{name: 'MISSISSIPPI', value: 'MISSISSIPPI'},
{name: 'PUTNAM', value: 'PUTNAM'},
{name: 'CLARK', value: 'CLARK'},
{name: 'ST. CLAIR', value: 'ST. CLAIR'},
{name: 'STE. GENEVIEVE', value: 'STE. GENEVIEVE'},
{name: 'PEMISCOT', value: 'PEMISCOT'},
{name: 'MORGAN', value: 'MORGAN'},
{name: 'ST CLAIR', value: 'ST CLAIR'},
{name: 'CAPE GIRARDEAU', value: 'CAPE GIRARDEAU'},
{name: 'LIVINGSTON', value: 'LIVINGSTON'},
{name: 'HOLT', value: 'HOLT'},
{name: 'SHANNON', value: 'SHANNON'},
{name: 'MCDONALD', value: 'MCDONALD'},
{name: 'CALDWELL', value: 'CALDWELL'},
{name: 'MERCER', value: 'MERCER'},
{name: 'WORTH', value: 'WORTH'},
{name: 'CHRISTIAN', value: 'CHRISTIAN'},
{name: 'CASS', value: 'CASS'},
{name: 'GASCONADE', value: 'GASCONADE'},
{name: 'CARROLL', value: 'CARROLL'},
{name: 'HOWARD', value: 'HOWARD'},
{name: 'DEKALB', value: 'DEKALB'},
{name: 'MADISON', value: 'MADISON'},
{name: 'DADE', value: 'DADE'},
{name: 'BATES', value: 'BATES'},
{name: 'LEWIS', value: 'LEWIS'},
{name: 'KNOX', value: 'KNOX'},
];

export const detectorCounties = [
  {name: 'ST. LOUIS CITY', value: 'ST. LOUIS CITY'},
  {name: 'FRANKLIN', value: 'FRANKLIN'},
  {name: 'LINCOLN', value: 'LINCOLN'},
  {name: 'JEFFERSON', value: 'JEFFERSON'},
  {name: 'MISSISSIPPI', value: 'MISSISSIPPI'},
  {name: 'ST. LOUIS', value: 'ST. LOUIS'},
  {name: 'WARREN', value: 'WARREN'},
  {name: 'ST LOUIS', value: 'ST LOUIS'},
  {name: 'CASS', value: 'CASS'},
  {name: 'ST LOUIS CITY', value: 'ST LOUIS CITY'},
  {name: 'PLATTE', value: 'PLATTE'},
  {name: 'RAY', value: 'RAY'},
  {name: 'ST. CHARLES', value: 'ST. CHARLES'},
  {name: 'ANDREW', value: 'ANDREW'},
  {name: 'JACKSON', value: 'JACKSON'},
  {name: 'ST CHARLES', value: 'ST CHARLES'},
  {name: 'CLAY', value: 'CLAY'},
];




