
export const supporttype = [
    {name: 'HOME', value: 'HOME'},
    {name: 'DASHBOARD', value: 'DASHBOARD'},
    {name: 'DATA DOWNLOAD', value: 'DATA DOWNLOAD'},
    {name: 'APP CENTER - SAFETY', value: 'APP CENTER - SAFETY'},
    {name: 'APP CENTER - PROBE', value: 'APP CENTER - PROBE'},
    {name: 'APP CENTER - TRAFFIC COUNTS', value: 'APP CENTER - TRAFFIC COUNTS'},
    {name: 'APP CENTER - TRAVEL TIME', value: 'APP CENTER - TRAVEL TIME'},
    {name: 'APP CENTER - TRAFFIC JAMS', value: 'APP CENTER - TRAFFIC JAMS'},
    {name: 'APP CENTER - WAZE ANALYTICS', value: 'APP CENTER - WAZE ANALYTICS'},
    {name: 'APP CENTER - WINTER SEVERITY', value: 'APP CENTER - WINTER SEVERITY'},
    {name: 'APP CENTER - CRASHES SHP', value: 'APP CENTER - CRASHES SHP'},
    {name: 'APP CENTER - CONGESTION', value: 'APP CENTER - CONGESTION'},
    {name: 'APP CENTER - INCIDENT CLEARANCE', value: 'APP CENTER - INCIDENT CLEARANCE'},
    {name: 'APP CENTER - ANALYTICS', value: 'APP CENTER - ANALYTICS'},
    {name: 'SUPPORT', value: 'SUPPORT'},
   
    
];

